import React, { useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import axios from 'axios';
import { Button,  Box, IconButton , Dialog, DialogTitle, DialogContent, DialogActions, Slide } from '@mui/material';

import CenterFocusStrongTwoToneIcon from '@mui/icons-material/CenterFocusStrongTwoTone';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import CameraswitchTwoToneIcon from '@mui/icons-material/CameraswitchTwoTone';

import SettingsIcon from '@mui/icons-material/Settings';
import LanguageIcon from '@mui/icons-material/Language';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

// const modalStyle = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 2,
//   width: '90vw', 
//   maxWidth: 400, 
//   borderRadius: 8,
//   '@media (min-width: 600px)': {
//     width: '50vw', 
//   },
// };

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const App = () => {
  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [thinking, setThinking] = useState(false);
  const [showReward, setShowReward] = useState(false);
  const [rewardData, setRewardData] = useState(false);


  const [openDialog, setOpenDialog] = useState(null);
  const handleOpen = (dialogId) => setOpenDialog(dialogId);
  const handleClose = () => setOpenDialog(null);

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: 'environment',
  };

  useEffect(() => {
    console.log(2);
    window.scrollTo({
      top: 1000,
      behavior: 'smooth',
    });
  }, []);

  const startCamera = () => {
    setCapturedImage(null);
  };

  const capturePhoto = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
  };

  const restartApp = () => {
    window.location.reload();
  };

  const sendToServer = async() => {
    if (capturedImage) {
      setThinking(true)
      // Remove the "data:image/jpeg;base64," part of the base64 string if present
      const base64Image = capturedImage.replace(/^data:image\/[a-z]+;base64,/, "");
      //  "https://vision.xertcoin.com/upload/image",
      // "http://localhost:5006/upload/image" 
      await axios
        .post(
          "https://vision.xertcoin.com/upload/image",
          { image: `data:image/jpeg;base64,${base64Image}` }, // Ensure image has the correct base64 structure
          {
            headers: {
              "Content-Type": "application/json", // Specify JSON content-type
            },
          }
        )
        .then((response) => {
          console.log("Image uploaded successfully", );
          setShowReward(true)
          setRewardData(response.data.file)
          setThinking(false)



          const myFunction = () => {

            const filePath = './rewards/0'+response.data.reward+'.jpg';
            fetch(filePath)
              .then((response) => response.blob())
              .then((blob) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setRewardData(reader.result.split(',')[1]); 
                };
                reader.readAsDataURL(blob); 
              });

          };


          setTimeout(myFunction, 2000);

        })
        .catch((error) => {
          setShowReward(false)
          // console.error("Error uploading image", error);
        });

        setThinking(false)
    }
  };

  return (
    <div style={{ width: "100vw", height: "100vh", position: "relative", overflow: "hidden" }}>


      {showReward && !thinking && (
        <Box
          sx={{
            backgroundColor: 'white',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={`data:image/jpeg;base64,${rewardData}`}
            alt="Centered"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
          />
        </Box>
      )}

      {thinking && !showReward && (
        <Box
          sx={{
            backgroundColor: 'white',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
           <img
      src="./200.gif"
      alt="Centered"
      style={{
        maxWidth: '100%',
        maxHeight: '80%',
        objectFit: 'contain',
      }}
    />
          <p style={{ marginTop: '20px', fontSize: '18px', color: 'black' }}>Please wait...</p>
        </Box>
      )}

      {!capturedImage && !thinking && !showReward && (
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          imageSmoothing={true}
          mirrored={false}
          screenshotQuality={1}
          style={{ position: "absolute", width: "100vw", height: "100vh", objectFit: "cover" }}
        />
      )}
 <div>
      {/* Buttons aligned vertically in the top-left corner */}
      <div
        style={{
          position: 'fixed',
          top: '10px',
          left: '10px',
          display: 'flex',
          flexDirection: 'column', // Vertical layout
          gap: '10px', // Space between buttons
        }}
      >
        {/* Settings Button */}
        <IconButton
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
            },
          }}
          onClick={() => handleOpen('settings')}
        >
          <SettingsIcon sx={{ fontSize: 40, color: '#ffe400' }} />
        </IconButton>

        {/* Website Button */}
        <IconButton
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
            },
          }}
          onClick={() => handleOpen('website')}
        >
          <LanguageIcon sx={{ fontSize: 40, color: '#ffe400' }} />
        </IconButton>

        {/* Support Button */}
        <IconButton
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
            },
          }}
          onClick={() => handleOpen('support')}
        >
          <SupportAgentIcon sx={{ fontSize: 40, color: '#ffe400' }} />
        </IconButton>
      </div>

      {/* Dialog for Settings */}
      <Dialog
        open={openDialog === 'settings'}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        PaperProps={{
          style: { borderRadius: 10 },
        }}
      >
        <DialogTitle>
          Settings
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8, color: 'gray' }}
          >
            ✖
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <p>Choose your settings:</p>
          <Button variant="contained" fullWidth sx={{ marginTop: 1 }}>Option 1</Button>
          <Button variant="contained" fullWidth sx={{ marginTop: 1 }}>Option 2</Button>
          <Button variant="contained" fullWidth sx={{ marginTop: 1 }}>Option 3</Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Website */}
      <Dialog
        open={openDialog === 'website'}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        PaperProps={{
          style: { borderRadius: 10 },
        }}
      >
        <DialogTitle>
          Website
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8, color: 'gray' }}
          >
            ✖
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <p>Explore website options:</p>
          <Button variant="contained" fullWidth sx={{ marginTop: 1 }}>Visit Page</Button>
          <Button variant="contained" fullWidth sx={{ marginTop: 1 }}>Open Docs</Button>
          <Button variant="contained" fullWidth sx={{ marginTop: 1 }}>Contact Support</Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Support */}
      <Dialog
        open={openDialog === 'support'}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        PaperProps={{
          style: { borderRadius: 10 },
        }}
      >
        <DialogTitle>
          Support
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8, color: 'gray' }}
          >
            ✖
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <p>Need help? Choose an option:</p>
          <Button variant="contained" fullWidth sx={{ marginTop: 1 }}>FAQ</Button>
          <Button variant="contained" fullWidth sx={{ marginTop: 1 }}>Live Chat</Button>
          <Button variant="contained" fullWidth sx={{ marginTop: 1 }}>Email Support</Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </div>

      {capturedImage && !thinking && !showReward && (
        <img
          src={capturedImage}
          alt="Captured"
          style={{ position: "absolute", width: "100%", height: "100%", objectFit: "cover" }}
        />
      )}

      {capturedImage && !thinking && !showReward && (
        <Box sx={{ position: "absolute", bottom: "20px", left: "2%" }}>
          
        
      <IconButton
      size="large"
      color="success"
      onClick={startCamera}
      sx={{
      backgroundColor: 'rgba(0, 0, 0, 0.5)', 
      '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.7)', 
      },
      }}
      >
      <CameraswitchTwoToneIcon
      sx={{ fontSize: 40, color: '#ffe400' }} 
      />
      </IconButton>
        </Box>
      )}

      {capturedImage && !thinking && showReward && (
        <Box
          sx={{
            position: "absolute",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          
          <IconButton
size="large"
color="success"
onClick={restartApp}
sx={{
backgroundColor: 'rgba(0, 0, 0, 0.5)', 
'&:hover': {
backgroundColor: 'rgba(255, 255, 255, 0.7)', 
},
}}
>
<CameraswitchTwoToneIcon
sx={{ fontSize: 40, color: '#ffe400' }} 
/>
</IconButton>
        </Box>
      )}

      {!capturedImage && !thinking && !showReward && (
        <Box
          sx={{
            position: "absolute",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
<IconButton
size="large"
color="success"
onClick={capturePhoto}
sx={{
backgroundColor: 'rgba(0, 0, 0, 0.5)', 
'&:hover': {
backgroundColor: 'rgba(255, 255, 255, 0.7)', 
},
}}
>
<CenterFocusStrongTwoToneIcon
sx={{ fontSize: 40, color: 'yellow' }} 
/>
</IconButton>
        </Box>
      )}

      {capturedImage && !thinking && !showReward && (
        <Box sx={{ position: "absolute", bottom: "20px", right: "2%" }}>

          <IconButton
size="large"
color="success"
onClick={sendToServer}
sx={{
backgroundColor: 'rgba(0, 0, 0, 0.5)', 
'&:hover': {
backgroundColor: 'rgba(255, 255, 255, 0.7)', 
},
}}
>
<CloudUploadTwoToneIcon
sx={{ fontSize: 40, color: '#ffe400' }}
/>
</IconButton>
        </Box>
      )}
    </div>
  );
};

export default App;